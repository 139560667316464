.site-carousel{
    // main books collections carousel
    align-items: flex-start;
    &#tns1{
        .slider-nav__item{
            .component__link{
                @media (min-width: 40em){
                    min-height: 50px;
                    margin-top: 20px;
                }
            }
            &.is-active {
                .component__link{
                    .book-title {
                        background-color: $white;
                        &.green-bordered {
                            border: 3px solid $green;
                            border-radius: 6px;
                        }
                        &.dark-blue-bordered {
                            border: 3px solid $dark-blue;
                            border-radius: 6px;
                        }
                        &.pink-bordered {
                            border: 3px solid $pink;
                            border-radius: 6px;
                        }
                        &.orange-bordered {
                            border: 3px solid $orange;
                            border-radius: 6px;
                        }
                        &.yellow-bordered {
                            border: 3px solid $yellow;
                            border-radius: 6px;
                        }
                        &.blue-bordered {
                            border: 3px solid $blue;
                            border-radius: 6px;
                        }
                        &.red-bordered {
                            border: 3px solid $red;
                            border-radius: 6px;
                        }
                        &.pale-orange-bordered {
                            border: 3px solid $pale-orange;
                            border-radius: 6px;
                        }
                        &.purple-bordered {
                            border: 3px solid $purple;
                            border-radius: 6px;
                        }
                        &.light-purple-bordered {
                            border: 3px solid $light-purple;
                            border-radius: 6px;
                        }
                    }
                }
            }
        }
    }
}
.activity-detail {
    .asset-detail {
        align-items: flex-start;
        &__image {
            img {
                @media (max-width: 767px) {
                    max-width: 75%;
                    margin: 0 auto;
                }
            }
        }
        &__info {
            justify-content: flex-start;
        }
        &__category {
            color: $dark-blue;
            font-family: "Open Sans", sans-serif;
            font-size: 16px;
            font-weight: bold;
            margin: 8px 0 0;
            @media (max-width: 767px) {
                text-align: center;
            }
        }
        &__title {
            color: #27720C;
            text-transform: capitalize;
            font-size: 38px;
            margin-bottom: 8px;
            @media (max-width: 767px) {
                text-align: center;
                font-size: 32px;
                line-height: 1.2;
                margin-top: 8px;
            }
        }
        &__description {
            color: $dark-grey;
            @media (max-width: 767px) {
                text-align: center;
                padding: 0 16px;
                margin-bottom: 48px;
            }
        }
        &__action {
            @include siteButton($green, $white);
            padding: 16px 24px;
            font-size: 18px;
            @media (max-width: 767px) {
                display: flex;
                width: 50%;
                justify-content: center;
                font-size: 28px;
                margin: 0 auto;
                padding: 24px;
            }
        }
        &__carousel {
            @include full_width_colour($white);
            &:after {
                z-index: 1;
            }
        }
        &__related-title {
            color: $red;
            text-transform: capitalize;
            font-size: 44px;
            @media (max-width: 767px) {
                text-transform: uppercase;
                margin: 0;
                padding: 24px 0;
                letter-spacing: 2px;
                font-size: 28px;
            }
        }
        &__related-subtitle {
            @media (max-width: 767px) {
                display: none;
            }
        }
        .related-book {
            @media (max-width: 767px) {
                flex-direction: column;
                justify-content: center;
            }
            h4 {
                color: #07123f;
                text-transform: none;
                line-height: 1.5;
                font-size: 28px;
                margin-bottom: 0;
                @media (max-width: 767px) {
                    flex-direction: column;
                    justify-content: center;
                    font-size: 24px;
                    line-height: 1.2;
                }
            }
            p {
                color: $dark-grey;
                margin-bottom: 8px;
            }
            &__content {
                @media (max-width: 767px) {
                    width: 100%;
                    padding: 16px;
                    text-align: center;
                    margin-top: 16px;
                }
            }
        }
    }
    .assets-carousel {
        &__book-title {
            @extend %primary-font;
            color: $dark-blue;
            text-transform: none;
            font-size: 18px;
        }
    }
}
@import "variables";
@import "typography";

body {
    background: $background-body;
}

main {
    overflow: hidden;
}

.white-background {
    background-color: $white;
    @include full_width_colour($white);
}

.yellow-background {
    background-color: $yellow;
    @include full_width_colour($yellow);
}

.pale-yellow-background {
    background-color: $pale-yellow;
    @include full_width_colour($pale-yellow);
}

.blue-background { 
    background-color: $dark-blue; 
    @include full_width_colour($dark-blue);
}

.light-purple-background {
    background-color: $light-purple;
    @include full_width_colour($light-purple);
}
.book-landing {
    .book-card {
        .book-title {
            a {
                color: $dark-blue;
            }
        }
    }
    .pagination-top, .pagination-bottom {
        margin-bottom: 0;
        .pagination {
            li {
                margin-bottom: 16px;
            }
        }
    }
}
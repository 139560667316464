.supafolio-loader-container {
    .supafolio-loader {
        border-top-color: $primary-color;
    }
}
.book-detail {
    .book-content {
        img {
            @media (max-width: 767px) {
                max-width: 75%;
            }
        }
    }
    .button-asset {
        @extend %main-font;
        text-transform: uppercase;
        background: $orange;
        font-weight: normal;
        width: max-content;
        display: flex;
        margin: 16px auto 0;
        padding: 0;
        a {
            padding: 15px 30px;
            color: white;
            width: 100%;
        }
        &--audio {
            padding: 10px 30px;
            box-shadow: none;
            background: transparent;
            &::before {
                margin-right: 16px;
            }
        }
        @media (max-width: 767px) {
            font-size: 20px;
            &.primary-button {
                width: 100%;
                justify-content: center;
                border-radius: 8px;
            }
        }
    }
    .book-title {
        font-size: 52px;
        color: $dark-blue;
        @media (max-width: 767px) {
            font-size: 46px;
        }
    }
    .book-contributors {
        font-size: 22px;
        text-transform: capitalize;
        color: $dark-blue;
    }
    .flextabs-container {
        @media (max-width: 767px) {
            width: auto;
            margin: 0 -16px 32px;
        }
    }
    .flextabs {
        &__tab button {
            @extend %primary-font;
            color: $red;
            text-transform: capitalize;
        }
        @media (min-width: 768px) {
            &__content {
                border-top: 2px solid $orange;
                &::before, &::after {
                    border-top: 2px solid $orange;
                    margin-top: -1px;
                }
            }
            &__toggle {
                &--active {
                    &--last {
                        border: 2px solid;
                        border-color: $orange $orange $white;
                        border-bottom: 0;
                        margin-bottom: -2px;
                    }
                }
            }
        }
        @media (max-width: 767px) {
            width: 100%;
            margin: 16px -16px 0;
            &::before, &::after {
                content: none;
            }
            &__tab {
                position: relative;
                padding: 8px 24px;
                margin-bottom: 8px;
                background: $red;
                font-size: 28px;
                button {
                    color: $white;
                }
            }
            &__toggle {
                &::after {
                    content: '+';
                    position: absolute;
                    right: 24px;
                    top: 50%;
                    transform: translateY(-50%);
                }
                &--active {
                    &::after {
                        content: '-';
                        position: absolute;
                        right: 24px;
                        top: 50%;
                        transform: translateY(-50%);
                        font-size: 58px;
                    }
                }
            }
            &__content {
                background: $white;
                padding: 16px 24px;
                text-align: center;
            }
        }
    }
    .buy-button-wrapper {
        background: $pale-blue;
        @media (max-width: 767px) {
            max-width: none;
            margin: 32px auto 24px;
            padding: 32px;
        }
        .retailers-wrapper {
            border: 1px solid $dark-grey;
        }
        .format {
            @extend %primary-font;
            color: $mid-blue;
            text-transform: capitalize;
            font-size: 25px;
        }
        .price {
            font-size: 22px;
        }
        .buy-button {
            background-color: $mid-blue;
            @media (max-width: 767px) {
                padding: 16px 48px;
                font-size: 25px;
            }
        }
    }
    .other-formats {
        @extend %primary-font;
        text-transform: capitalize;
        p {
            font-size: 22px;
            color: $mid-blue;
            margin: 3px 0 16px;
            &::after {
                content: ':';
            }
        }
        li {
            margin-bottom: 16px;
        }
        a {
            font-size: 22px;
            color: $red;
        }
        @media (max-width: 767px) {
            display: flex;
            p {
                font-size: 28px;
            }
            a {
                font-family: "Open Sans", sans-serif;
                margin-left: 12px;
            }
        }
    }
    .related-books {
        width: 100%;
        &::before {
            content: "";
            height: 80px;
            background: url("https://supadu-io.imgix.net/harpercollins-petethecat-ssg/petethecat-site/separator-decorative.png?auto=format") center;
            width: 100%;
            background-repeat: no-repeat;
            background-size: contain;
            display: block;
            margin: 0 auto;
            @media (max-width: 767px) {
                content: none;
            }
        } 
        &-title {
            margin-top: 0;
            padding: 16px 0 24px;
            h1 {
                text-transform: uppercase;
                margin: 0;
                padding: 0;
                font-size: 28px;
                color: $red;
                @media (min-width: 768px) {
                    text-transform: capitalize;
                }
            }
        }
    }
    .books-carousel {
        img {
            max-width: 100%;
        }
        padding: 0 16px;
    }
}

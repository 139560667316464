.newsletter {
    h2 {
        color: $pink;
        text-align: center;
        padding-bottom: 16px;
        font-size: 28px;
        @media (min-width: 768px) {
            text-align: left;
            padding: 0 32px 0 0;
            font-size: 32px;
        }
    }
    .sailthru-form {
        &__email {
            label {
                text-transform: capitalize;
            }
        }
        &__dob {
            label {
                text-transform: capitalize;
            }
            .select-style {
                border-radius: 8px;
            }
        }
        &__submit {
            button {
                background-color: $pink;
                text-transform: capitalize;
                letter-spacing: 2px;
                &:hover {
                    filter: brightness(1.2);
                }
            }
        }
    }
}
.main-navigation {
    background-color: $nav-background-colour;
    @media (min-width: 768px) {
        padding: 0 8px;
    }
    &.show-menu {
        padding-bottom: 0;
    }
    .main-menu {
        @media (max-width: 767px) {
            margin-top: 8px;
        }
        li {
            margin: 0;
            a {
                height: 100%;
                display: block;
                padding: 8px 16px;
                color: $nav-links-colour;
                @extend %primary-font;
                font-size: 20px;
                line-height: 25px;
                @media (max-width: 767px) {
                    padding: 8px;
                    margin: 0 -8px;
                }
                &:hover {
                    background-color: $nav-links-background-colour-hover;
                    color: $nav-links-colour-hover;
                }
                &.is-active {
                    background-color: $nav-current-link-background-colour;
                    color: $nav-links-colour-hover;
                }
            }
        }
    }
}
$white: white;
$max-width: 1060px;
$grey: #5f5f5f;
$light-grey: #eae9e8;
$dark-grey: #2d2b2b;
$black: #000;
$green: #4CAC2C;
$blue: #07BFDE;
$mid-blue: #0994A5;
$pale-blue: #D5E9EF;
$dark-blue: #0055A5;
$light-purple: #835EB9;
$purple: #34306A;
$pale-yellow: #FFF4B3;
$yellow: #FFE200;
$orange: #E67924;
$pale-orange: #EFA802;
$red: #CE2832;
$pink: #C2366C;
$animation-time: 0.3s;
$section-border-top: $red;
$background-body: $white;
$body-font-color: $black;
$primary-color: $red;
$secondary-color: $blue;
$background-buy-section: $pale-blue;

$assetBackgrounds: $purple, $dark-blue, $green, $mid-blue, $light-purple, $red, $pink;

/* Header */

$header-background-color: $blue;

/* NAVIGATION */

$nav-links-colour: $white;
$nav-links-colour-hover: $white;
$nav-links-background-colour-hover: $dark-blue;
$nav-current-link-colour: $white;
$nav-current-link-background-colour: $dark-blue;
$nav-background-colour: $green;

/* default font sizes */

$base-font-size: 16px;
$h1-font-size: 44px;
$h2-font-size: 36px;
$h3-font-size: 26px;
$h4-font-size: 18px;
$h5-font-size: 16px;

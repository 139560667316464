.home {
  .hero {
    &__container {
      &--left {
        @media (min-width: 768px) and (max-width: 1023px) {
          &.md\:w-2\/5 {
            width: 50%;
          }
        }
        h1 {
          @include breakpoint(large-tablet) {
            font-size: 40px;
            line-height: 40px;
          }
        }
      
        h2 {
          margin-bottom: 16px;
          font-size: 38px;
          line-height: 38px;
          text-transform: none;
          letter-spacing: 2px;
          @include breakpoint(large-tablet) {
            font-size: 52px;
            line-height: 52px;
          }
        }
      }
      &--right {
        @media (min-width: 768px) and (max-width: 1023px) {
          &.md\:w-3\/5 {
            width: 50%;
          }
        }
      }
    }

    .component {
      &__link {
        a {
          text-transform: none;
        }
      }
    }
  }


  .home-carousel {
    @media (min-width: 900px) and (max-width: 1280px) {
      max-width: 95%;
    }
  }

  .container-embed {
    @media (min-width: 1280px) {
      max-width: 1280px;
      margin: 0 auto;
    }
  }

  .youtube-embed {
    .embed-video {
      @media (max-width: 1023px) {
        flex-wrap: wrap;
        max-height: none;
      }
      &__iframe {
        &--playlist {
          @media (max-width: 1023px) {
            width: 100%;
            border-right: none;
          }
          @media (min-width: 1024px) {
            width: 65%;
          }
        }
      }
      &__playlist {
        @media (max-width: 1023px) {
          display: flex;
          flex-direction: row;
          overflow-x: scroll;
          overflow-y: hidden;
          width: 100%;
          float: unset;
          margin-top: 24px;
        }
        @media (min-width: 1024px) {
          width: 35%;
        }
        &__item {
          color: $white;
          @media (max-width: 1023px) {
            flex-direction: column;
            flex: 0 0 33%;
            &:not(:last-of-type) {
              margin-right: 8px;
            }
            &__thumb {
              width: 100%;
            }
            &__desc {
              margin-left: 0;
              padding: 8px 0 0;
              .item-title {
                max-height: none;
                line-height: 1.3;
              }
            }
          }
          @media (min-width: 1024px) {
            height: 30%;
            width: 100%;
            flex-direction: row;
            margin-right: 0;
            &__thumb {
              height: 100%;
              width: 45%;
              background-size: contain;
              background-repeat: no-repeat;
            }
            &__desc {
              margin: 16px 0 0 16px;
              .item-title {
                font-size: 13px;
              }
            }
          }
          &:hover,
          &.active {
            background-color: transparent;
          }
        }
      }
    }
  }
}